import React, { useMemo } from 'react';
import classNames from 'classnames';

import Layout from '../components/layout';
import Seo from '../components/seo';
import SectionTitle from '../components/section-title';
import Pagination from '../components/pagination';
import EventCard from '../components/event-card';

import * as Styles from '../styles/pages/_event.module.scss';

const Event = ({ location, pageContext }) => {
  const {
    nodes,
    prevPagePath,
    nextPagePath,
    pagination,
    currentPageNum,
    totalPageNum,
    // totalCount,
  } = pageContext;

  /** 2ページ以上ページネーションが存在すれば true */
  const isPageNation = useMemo(() => totalPageNum > 1, [totalPageNum]);

  return (
    <>
      <Seo title="イベント一覧" url={location.origin} />
      <Layout location={location.pathname}>
        <section>
          <div className={Styles.event_page__header}>
            <SectionTitle>event</SectionTitle>
            <h1 className={Styles.event_page__title}>イベント一覧</h1>
          </div>

          <div className={Styles.event_page__content}>
            <div className={Styles.event_page__container}>
              <ul className={classNames('row', Styles.event_page__list)}>
                {nodes?.map((node) => (
                  <li className="col_4" key={node.eventId}>
                    <EventCard {...node} />
                  </li>
                ))}
              </ul>

              {/* pagination */}
              {isPageNation && (
                <Pagination
                  currentPageNum={currentPageNum}
                  pagination={pagination}
                  prevPagePath={prevPagePath}
                  nextPagePath={nextPagePath}
                />
              )}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Event;
