import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import * as Styles from '../styles/_pagination.module.scss';

export default function Pagination(props) {
  const {
    currentPageNum = 1, // 現在のページ
    pagination = [], // ページ一覧情報
    // prevPagePath = null, // 前のページの情報
    // nextPagePath = null, // 次のページの情報
  } = props;

  return (
    <div className={Styles.pagination}>
      {/* {prevPagePath && (
          <Link className={Styles.prev} to={prevPagePath}>
            前のページへ
          </Link>
        )}
        {nextPagePath && (
          <Link className={Styles.next} to={nextPagePath}>
            次のページへ
          </Link>
        )} */}
      <ul className={Styles.pagination__list}>
        {pagination.map((v) => (
          <li className={Styles.pagination__list__item} key={v.pageNum}>
            <Link
              to={v.path}
              className={classNames(Styles.pagination__link, {
                [Styles.pagination__current_link]:
                    currentPageNum === v.pageNum,
              })}
            >
              {v.pageNum}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
